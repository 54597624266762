import { ROUTE_NAMES as ADMIN_ROUTES } from "@/modules/admin";
import { ROUTE_NAMES as AUDITOR_ROUTES } from "@/modules/auditor";
import { useAuthStore } from "@/stores/auth";
import { UserRole } from "@/types/user";

export const useHomeURL = () => {
  const authStore = useAuthStore();

  return computed(() => {
    if (!authStore.user?.role) {
      return null;
    }

    switch (authStore.user.role) {
      case UserRole.Superadmin:
        return { name: ADMIN_ROUTES.Main };
      case UserRole.Manager:
        return { name: AUDITOR_ROUTES.Assistants };
      case UserRole.Assistant:
        return { name: AUDITOR_ROUTES.Companies };
      case UserRole.Companyadmin:
        return { name: "homepage" };
      default:
        return { name: "homepage" };
    }
  });
};
